import config from "./config.json";

import * as Backend from "@faintlines/backend-client";
import { ChatStore } from "@faintlines/chat-client";

import { createContext, useContext } from "react";
import { makeAutoObservable, autorun, toJS, runInAction } from "mobx";
import store from "store";

const PERSIST_STORE_KEY = "state";

// Store the login credentials and NOT the session id, since the session might be
// invalid by the next reload.
const PERSIST_KEYS = ["loginCreds"];

export class Store {
    loginTokenValue = "";
    loggingIn = false;
    loginError = "";

    loginCreds = null;
    sessionId = null;
    redirectUrl = null;

    constructor({ loginToken }) {
        const existingState = store.get(PERSIST_STORE_KEY);
        if (existingState) {
            PERSIST_KEYS.forEach((key) => {
                if (existingState[key]) {
                    this[key] = existingState[key];
                }
            });
        }

        if (loginToken) {
            this.loginTokenValue = loginToken;
            this.loginCreds = { token: loginToken };
        }

        makeAutoObservable(this, {
            initChatClientStore: false,
        });

        if (!config.login) {
            this.initChatClientStore();
        }

        autorun(() => {
            const persistedState = {};
            PERSIST_KEYS.forEach((key) => {
                persistedState[key] = toJS(this[key]);
            });
            store.set(PERSIST_STORE_KEY, persistedState);
        });

        if (this.loginCreds) {
            this.loginWithCreds(this.loginCreds);
        }
    }

    get loggedIn() {
        return this.sessionId !== null;
    }

    get activeChatId() {
        return this.chatStore?.activeChatId;
    }

    setLoginTokenValue(value) {
        this.loginTokenValue = value;
    }

    login() {
        if (!this.loginTokenValue) {
            return;
        }

        this.loginWithCreds({ token: this.loginTokenValue });
    }

    loginWithCreds(creds) {
        if (this.loggingIn) {
            return;
        }

        this.loggingIn = true;
        this.loginError = "";

        Backend.storyInteract({
            interactionType: "login",
            data: creds,
        })
            .then(({ data }) =>
                runInAction(() => {
                    const {
                        success,
                        session_id: sessionId,
                        chats,
                        state,
                        error,
                    } = data;

                    if (success) {
                        this.initChatClientStore(sessionId, chats, state);
                        this.loginCreds = creds;
                        this.sessionId = sessionId;
                    } else {
                        this.loginError = error;
                    }
                })
            )
            .catch(() => {
                runInAction(() => {
                    this.loginError = "Failed logging in, please try again.";
                });
            })
            .finally(() => {
                runInAction(() => {
                    this.loggingIn = false;
                });
            });
    }

    startChat(chatId) {
        return new Promise((resolve) => {
            if (isValidChatId(chatId)) {
                this.chatStore.setActiveChat(chatId);
                resolve({ success: true });
            } else {
                resolve({ error: "No such user" });
            }
        });
    }

    endChat() {
        this.chatStore.unsetActiveChat();
    }

    handleChatStart({ chatId }, sessionId) {
        return new Promise((resolve, reject) => {
            Backend.storyInteract({
                interactionType: "start",
                sessionId: sessionId || this.sessionId,
                data: { chatId },
            })
                .then(({ data }) => {
                    resolve({ data });
                })
                .catch(reject);
        });
    }

    handleChatMessage({ chatId, stateId, messageText }) {
        return new Promise((resolve, reject) => {
            Backend.storyInteract({
                interactionType: this.sessionId ? "message" : "message_anon",
                sessionId: this.sessionId,
                data: {
                    chatId,
                    stateId,
                    messageText,
                },
            })
                .then(({ data }) => resolve({ data }))
                .catch(reject);
        });
    }

    setSessionId(sessionId) {
        this.sessionId = sessionId;
    }

    handleStateChange(state) {
        Backend.storyInteract({
            interactionType: "state",
            sessionId: this.sessionId,
            data: { state },
        });
    }

    handleChatEvent(evt) {
        const { data } = evt;
        if (data.redirect) {
            window.location.href = data.redirect;
            this.redirectUrl = data.redirect;
        }
    }

    handleChatQueryExternalEvents({ processed }) {
        return new Promise((resolve, reject) => {
            Backend.storyInteract({
                interactionType: "query_events",
                sessionId: this.sessionId,
                data: { processed },
            })
                .then(({ data }) => resolve({ data }))
                .catch(reject);
        });
    }

    initChatClientStore(sessionId, chats, state) {
        this.chatStore = new ChatStore(
            {
                ...config.chat,
                ...(chats ? { chats } : null),
                persistenceKey: sessionId,
                onChatStart: (evt) => this.handleChatStart(evt, sessionId),
                onMessage: (evt) => this.handleChatMessage(evt),
                onReceivedSessionId: (newSessionId) =>
                    this.setSessionId(newSessionId),
                onStateChange: config.chat.serverState
                    ? (newState) => this.handleStateChange(newState)
                    : null,
                onEvent: (evt) => this.handleChatEvent(evt),
                onQueryExternalEvents: (evt) =>
                    this.handleChatQueryExternalEvents(evt),
            },
            state
        );
    }
}

function isValidChatId(chatId) {
    return config.chat.chats.filter((x) => x.id === chatId).length > 0;
}

export const StoreContext = createContext(null);
export function useStore() {
    return useContext(StoreContext);
}
